<template>
  <v-container id="types" fluid tag="section" class="mt-10">
    <v-row>
      <v-col cols="12" md="8">
        <material-card color="primary" full-header>
          <template #heading>
            <div class="pa-8 white--text">
              <div class="text-h4">{{ getHeading }}</div>
            </div>
          </template>

          <v-card-text v-if="data_is_loaded" class="mt-5">
            <div
              class="
                list-config
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <div class="tableConfig d-inline-flex">
                <!-- Reload data !-->
                <div class="text-center">
                  <a href="#" @click="reloadData($event)">
                    <v-icon color="blue"> mdi-refresh </v-icon>
                  </a>
                </div>
              </div>

              <v-spacer />

              <div class="d-inline-flex">
                <div>
                  <base-button
                    :is_accent="true"
                    text="Add new"
                    :to="getNewUrl"
                  />
                </div>

                <v-toolbar
                  class="short-toolbar pt-1"
                  elevation="0"
                  hide-details
                  dense
                >
                  <v-text-field
                    v-model="search"
                    prepend-icon="mdi-magnify"
                  ></v-text-field>
                </v-toolbar>
              </div>
            </div>

            <v-expansion-panels ref="objects" class="pa-10">
              <v-expansion-panel
                v-for="(object, key) in filtredObjectsList"
                :key="key"
              >
                <v-expansion-panel-header>
                  {{ get_group_title(object) }}
                  <span class="font-italic pl-2">
                    ({{
                      null != object.devices ? object.devices.length : 0
                    }}
                    device{{
                      null == object.devices ||
                      (null != object.devices && 1 != object.devices.length)
                        ? "s"
                        : ""
                    }})
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <div class="mt-5 flex-row">
                      <span v-if="canUserEdit(object)">
                        <router-link :to="getViewUrl(object.id)">
                          <v-icon color="blue"> mdi-magnify </v-icon>
                        </router-link>
                      </span>
                      <span v-if="canUserEdit(object)">
                        <router-link :to="getEditUrl(object.id)">
                          <v-icon color="blue"> mdi-pencil </v-icon>
                        </router-link>
                      </span>
                      <span>
                        <a
                          to="#"
                          @click="deleteObject($event, object.id, object.name)"
                        >
                          <v-icon color="blue"> mdi-delete </v-icon>
                        </a>
                      </span>
                    </div>

                    <div class="sub-container" v-if="null != object.author">
                      <div class="flex-row">
                        <div class="column thin">Owner:</div>
                        <div class="column wide">
                          {{
                            null != object.author ? object.author.username : ""
                          }}
                        </div>
                      </div>

                      <div class="flex-row">
                        <div class="column thin">Team:</div>
                        <div class="column wide">
                          {{ null != object.team ? object.team.name : "" }}
                        </div>
                      </div>
                    </div>

                    <div class="sub-container" v-if="null != object.icon">
                      <div class="flex-row">
                        <div class="column thin">Icon:</div>
                        <div class="column wide">
                          <icon-image :image_src="object.icon" />
                        </div>
                      </div>
                    </div>

                    <div class="sub-container">
                      <div class="flex-row">
                        <div class="column thin">Type:</div>
                        <div class="column wide">
                          <span v-if="object.type == null" class="font-italic"
                            >Undefined</span
                          >
                          <span v-else>{{ object.type.name }}</span>
                        </div>
                      </div>

                      <div class="flex-row">
                        <div class="column thin">Firmware:</div>
                        <div class="column wide">
                          <span
                            v-if="object.target_firmware == null"
                            class="font-italic"
                          >
                            <v-icon color="alert" class="pl-0 pr-1">
                              mdi-book-off-outline </v-icon
                            >(undefined)
                          </span>
                          <span v-else
                            ><router-link
                              :to="
                                getFirmwareViewUrl(object.target_firmware.id)
                              "
                              >{{ object.target_firmware.name }}</router-link
                            >
                            ({{ object.target_firmware.version }})</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="subgroup sub-container">
                      <documentation-input
                        :disabled="true"
                        :doc_list_original="object.basic_docs_list"
                        :type_id_to_load="object.type_id"
                      />
                    </div>

                    <div class="subgroup sub-container">
                      <devices-in-group
                        :devices="object.devices"
                        :group_id="object.id"
                        :type_id="object.type.id"
                        :type_name="object.type.name"
                        :disabled="true"
                      />
                    </div>

                    <div class="subgroup sub-container">
                      <div class="flex-row">
                        <div class="column thin">Notes:</div>
                        <div class="column wide">
                          <textarea
                            class="my-0"
                            :rows="
                              null == object['notes'] ||
                              0 == object['notes'].length
                                ? 1
                                : 5
                            "
                            type="text"
                            id="notes"
                            :value="object['notes']"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getViewUrl,
  getEditUrl,
  getNewUrl,
  getCategoryLabel,
  isDevice,
  isGroup,
  isFirmware,
  getTypeCategory,
  getGroupCategory,
  getFirmwareCategory,
  OBJECT_CATEGORIES
} from "../utils/RoutesUtils";
import { deleteObject, getObjectsList, getTeamsListWhereUserIsAdminCall } from "../utils/ServerUtils.js";
import DropdownList from "../layouts/default/widgets/DropdownList.vue";
import DevicesInGroup from "../layouts/default/widgets/DevicesInGroup.vue";

import IconImage from "../components/app/IconImage.vue";
import CustomFieldsView from "../components/app/CustomFieldsView.vue";
import BaseButton from "../components/app/BaseButton.vue";
import { mapGetters, mapActions } from "vuex";
import DocumentationInput from '../layouts/default/widgets/DocumentationInput.vue';

export default {
  components: {
    DropdownList,
    IconImage,
    CustomFieldsView,
    DevicesInGroup,
    BaseButton,
    DocumentationInput
  },
  name: "GroupsList",

  props: {
    user: Object
  },

  data() {
    return {
      object_category: OBJECT_CATEGORIES.GROUP,

      objects_list: [],
      table_columns: [],

      data_is_loaded: false,
      
      teams_where_user_is_admin: [],

      search: ""
    };
  },

  watch: {
    search: function() {
      // trigger filtredObjectsList change
      const objects_list = this.objects_list;
      this.objects_list = objects_list;
    }
  },

  mounted: function() {
    this.loadData();
    this.getTeamsWhereUserIsAdmin();
  },

  computed: {
    ...mapGetters(["filter_selected_team_id"]),
    ...mapGetters(["filter_selected_type_id"]),

    filtredObjectsList: function() {
      var filtred_objects_list = [];
      for (let i = 0; i < this.objects_list.length; i++) {
        const object_i = this.objects_list[i];
        if (
          !this.isFilteredByTeam(object_i) &&
          !this.isFilteredByType(object_i) &&
          !this.isFilteredBySearch(object_i)
        ) {
          filtred_objects_list.push(object_i);
        }
      }
      return filtred_objects_list;
    },

    getHeading: function() {
      const wait_server_indicator = this.data_is_loaded
        ? ""
        : " [waiting for server response]";

      const objects_num_string =
        null == this.filter_selected_team_id &&
        null == this.filter_selected_type_id
          ? this.objects_list.length
          : this.filtredObjectsList.length + "/" + this.objects_list.length;
      return (
        getCategoryLabel(this.object_category, true) +
        " (" +
        objects_num_string +
        ")" +
        wait_server_indicator
      );
    },

    hasTargetFirmware: function() {
      return isDevice(this.object_category) || isGroup(this.object_category);
    },

    hasType: function() {
      return (
        isDevice(this.object_category) ||
        isGroup(this.object_category) ||
        isFirmware(this.object_category)
      );
    },

    hasDate: function() {
      return isDevice(this.object_category) || isFirmware(this.object_category);
    },

    isDevice: function() {
      return isDevice(this.object_category);
    },

    isFirmware: function() {
      return isFirmware(this.object_category);
    },

    getNewUrl: function() {
      return getNewUrl(this.object_category);
    },

    getTypeCategory() {
      return getTypeCategory();
    },

    getGroupCategory() {
      return getGroupCategory();
    }
  },

  methods: {
    ...mapActions(["updateSelectedTypeId"]),
    ...mapActions(["updateSelectedGroupId"]),
    ...mapActions(["updateLastUpdateTime"]),

    async getTeamsWhereUserIsAdmin() {
      const objects_list = await getTeamsListWhereUserIsAdminCall();
      if (null != objects_list) this.teams_where_user_is_admin = objects_list;
    },

    get_group_title: function(object) {
      if (this.filter_selected_type_id == null) {
        return object.name + " / " + object.type.name;
      } else {
        return object.name;
      }
    },

    formatDate: function(object_date) {
      var d = Date.parse(object_date);
      return new Date(d).toLocaleDateString();
    },

    getViewUrl: function(object_id) {
      return getViewUrl(object_id, this.object_category);
    },

    getFirmwareViewUrl: function(object_id) {
      return getViewUrl(object_id, getFirmwareCategory());
    },

    getEditUrl: function(object_id) {
      return getEditUrl(object_id, this.object_category);
    },

    deleteObject: async function(e, object_id, object_name) {
      e.preventDefault();
      await deleteObject(object_id, this.object_category, object_name);
      this.loadData();
    },

    loadData: async function() {
      this.data_is_loaded = false;
      const objects_list = await getObjectsList(this.object_category);
      if (null != objects_list) this.objects_list = objects_list;
      this.data_is_loaded = true;

      this.updateLastUpdateTime();
    },

    reloadData: function(event) {
      event.preventDefault();
      this.loadData();
    },

    isFilteredByTeam(object) {
      if (this.isTeam) return false;

      return (
        null != this.filter_selected_team_id &&
        object.team_id != this.filter_selected_team_id
      );
    },

    isFilteredByType(object) {
      return (
        this.hasType &&
        null != this.filter_selected_type_id &&
        object.type_id != this.filter_selected_type_id
      );
    },

    isFilteredBySearch(object) {
      return (
        this.search.length > 0 &&
        !object.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },    
        
    canUserEdit(object) {
      for (const admin_team of this.teams_where_user_is_admin) {
        if (admin_team.id == object.team_id) return true;
      }
      return false;
    },
  }
};
</script>

<style scoped>
.subgroup {
  margin-top: 1em;
}
.modal-content {
  padding: 1em;
  margin: 1em;
}
</style>
